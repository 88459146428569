import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import SiteLayout from './layout/SiteLayout';
import { useAppContext } from './contexts/app.context';
import { ampli } from './services/ampliService';
//@ts-ignore
import { navigateToLogout } from '@compasspathways/gjallarhorn';

ampli.load({ environment: 'development', client: { apiKey: window.__RUNTIME_CONFIG__.REACT_APP_AMPLITUDE_API_KEY || '', config: { logLevel: 'INFO' } } });

const authIssuerUrl = window.__RUNTIME_CONFIG__.AUTH_ISSUER_URL;

function AppSMS() {
	const { userInfo } = useAppContext();

	if (!userInfo) {
		return <div>Authenticating...</div>;
	}

	return (
		<Router>
			<div className="App">
				<SiteLayout signOutHandler={() => navigateToLogout({ baseUrl: authIssuerUrl })} />
			</div>
		</Router>
	)
}

export { AppSMS };
