import { gql, useQuery } from "@apollo/client";
import { Institution, Organization } from "../types";

import { showNotification } from "../../notificationService";

const CURRENT_USER_QUERY = gql(`
  query CurrentUser {
    currentUser {
      id
      email
      phone
      firstName
      lastName
      name
      createdAt
      updatedAt
      institutions {
        id
        name
        siteNumber
        organization {
          id
        }
      }
      organizations {
        id
        name
        protocolId
        code
        createdAt
      }
    }
    me {
      __typename
      ... on UserIdentity {
        roles
        permissions
      }
    }
  }
`);

/**
 * TODO: consider adding typegen
 */
export type CurrentUserData = {
  currentUser: {
    id: string
    email: string
    phone: string
    firstName: string
    lastName: string
    name: string
    createdAt: string
    updatedAt: string
    institutions: Array<Institution>
    organizations: Array<Organization>
  },
  me: {
    __typename: "UserIdentity";
    permissions: Array<string>
    roles: Array<string>
  };
};

export const useCurrentUserApi = () => {
  const { data: currentUser, loading: isCurrentUserLoading } = useQuery<CurrentUserData>(CURRENT_USER_QUERY, {
    onError(error) {
      console.error(error);

      showNotification('error', 'Failed to load session. Please try again later.');
    },
  });

  return {
    currentUser,
    isCurrentUserLoading,
  };
};
